.support  {
  padding: 72px 0 60px;

  @media (max-width: $mobile) {
    padding: 30px 0;
  }
}

.support__wrap {
  border: 4px solid $pink;
  padding: 60px 28px;

  @media (max-width: $mobile) {
    border: 2px solid $pink;
    padding: 30px 15px;
  }
}

.support__inner {
  max-width: 947px;
  margin: 0 auto;
}

.support__title {
  margin-bottom: 33px;
  color: $pink;

  @media (max-width: $mobile) {
    margin-bottom: 20px;
  }
}

.support__desc {
  @media (max-width: $mobile) {
    padding: 0 15px;
  }
}