.next {
  padding: 60px 0 48px;
  color: #fff;
  font-weight: 400;

  @media (max-width: $mobile) {
    padding: 30px 0px;
  }
}

.next__title {
  margin-bottom: 40px;
  color: #fff;

  @media (max-width: 580px) {
    padding: 0 20px;
    margin-bottom: 20px;
  }
}

.next__body {
  display: flex;
  justify-content: space-between;
  gap: 38px;
  align-items: center;
  margin-bottom: 40px;

  @media (max-width: 580px) {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }

  @media (max-width: $mobile) {
    margin-bottom: 30px;
  }
}

.next__qr-block {
  flex-shrink: 0;
  width: 182px;
  height: 182px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 60px rgba(255, 255, 255, 0.5);

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 580px) {
    order: 1;
  }

  @media (max-width: $mobile) {
    width: 196px;
    height: 196px;
  }
}

.next__step {
  max-width: 375px;
}

.next__step:last-child {
  max-width: 491px;

  @media (max-width: 580px) {
    max-width: 375px;
    order: 2;
  }
}

.next__step-title {
  margin-bottom: 15px;
  font-size: 32px;
  font-weight: bold;
  text-shadow: rgba(#fff, .5) 0 0 10px;

  @media (max-width: $tablet) {
    font-size: 16px;
  }
}

.next__step-desc {
  line-height: 1.15;

  @media (max-width: $tablet) {
    font-size: 14px;
  }
}

.next__info {
  margin-bottom: 15px;
}

.next__bottom {
  @media (max-width: $mobile) {
    font-size: 8px;

    &::before {
      content: "**";
    }
  }
}