/* Global */

:root {
  box-sizing: border-box;
  // --bg: rgb(255, 255, 0);
  // background-color: var(--bg);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.content-box-component {
  box-sizing: content-box;
}

html {
  height: 100%;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 1.33;
  color: #333333;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: $desktop) {
    font-size: 18px;
  }

  @media (max-width: $tablet) {
    font-size: 16px;
  }

  @media (max-width: $mobile) {
    font-size: 14px;
  }
}

body {
  position: relative;
  height: 100%;
}

.-weekend- {
  color: #e3e3e3;
}

button,
input {
  outline: none;
  border: none;
  cursor: pointer;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ul li,
ol li {
  list-style: none;
}

dd {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  max-height: 100%;
}

// img {
//   width: 100%;
//   height: 100%;
//   object-fit: contain;
//   flex-shrink: 0;
// }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.wrapper {
  min-height: 100%;
  min-width: 320px;
  display: grid;
  grid-template-rows: 1fr min-content;
  overflow: hidden;
}

.container {
  margin: 0 auto;

  @media (min-width: 1460px) {
    width: 1416px;
  }

  @media (max-width: 1459px) {
    padding: 0 25px;
  }

  @media (max-width: 370px) {
    padding: 0 10px;
  }
}

// Если в браузере отключен javascript
.no-js {
  & .wrapper::before {
    content: "Пожалуйста, разрешите использовать JavaScript! Без него сайт грустит...";
    position: fixed;
    top: -300px;
    left: 0;
    width: 100%;
    padding: 20px;
    z-index: 99999;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    color: rgb(241, 83, 94);
    background-color: rgba(0, 0, 0, 0.75);
    animation-name: real;
    animation-delay: 7s;
    animation-duration: 10s;
    animation-fill-mode: forwards;
  }
}

@keyframes real {
  0% {
    top: -300px;
  }
  10% {
    top: 0;
  }
  90% {
    top: 0;
  }
  100% {
    top: -300px;
  }
};

.colored {
  font-weight: bold;
  color: #FF5C00;
}

.info {
  font-size: 12px;

  @media (max-width: 1200px) {
    font-size: 8px;
  }
}

.p {
  padding: 0;
  margin: 0 0 20px;

  @media (max-width: $mobile) {
    margin-bottom: 15px;
  }

  &:last-child {
    margin: 0;
  }
}

.bg-gradient {
  background-image: linear-gradient(to right, rgba(#6558B0, .97) 0%, rgba(#C961AA, .97) 100%);

  @media (max-width: $mobile) {
    background-image: linear-gradient(to bottom, rgba(#6558B0, .97) 0%, rgba(#C961AA, .97) 100%);
  }
}

b {
  font-weight: 700;
}


// Стили для страницы privacy из Tilda
#inputResult {
  font-size: 1rem;
  h4 {
    font-size: 1.8rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  .ul,
  .ol {
    margin: 20px;
  }

  dd {
    margin: initial;
  }

  mark, .mark {
    background-color: yellow !important;
  }
}

// Подзагрузка img

.img-load {
  background-image: url("../img/loading.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
}