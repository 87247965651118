.header__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.header__logo-block {
  display: block;
  width: 327px;
  height: 96px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 1200px) {
    max-width: 238px;
    max-height: 70px;
  }
}

.header__nav-list {
  display: flex;
  align-items: center;
}

.header__nav-link {
  display: block;
  padding: 17.5px;
  color: $pink;
  font-weight: 700;
}

.header__btn {
  padding: 20px 68px;
  font-weight: 700;
  text-align: center;
  background-image: linear-gradient(to right, #E0B808 0%, #F18A2B 100% );
  color: #fff;
  letter-spacing: .5px;
}

.header__nav {
  display: flex;
  gap: 17.5px;

  @media (max-width: $desktop) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -10000px;
    z-index: 4;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    transform: scale(0.5);
    opacity: 0;
    border-radius: 100px;
    transition: transform 0.3s, opacity 0.3s, border-radius 0.3s;
  }

  &_active {
    right: 0;
    left: 0;
    padding: 0 20px;
    transform: scale(1);
    opacity: 1;
    border-radius: 0px;

    & .header__nav-list {
      position: relative;
      flex-direction: column;
    }
  }
}

.header__burger {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -10000px;
  z-index: 6;
  padding: 15px;
  background-color: transparent;
  border: none;

  &.open {
    position: fixed;
    top: 40px;
    right: 15px;
  }

  & span {
    display: block;
    position: absolute;
    height: 2.4px;
    width: 100%;
    border-radius: 20px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    background-color: #6C217F;
  }

  @media (max-width: $desktop) {
    right: 10px;
  }
}

.header__burger-inner {
  position: relative;
  width: 20px;
  height: 15px;
}

.header__burger span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

.header__burger span:nth-child(2) {
  top: 6.5px;
  transform-origin: left center;
}

.header__burger span:nth-child(3) {
  top: 13px;
  transform-origin: left center;
}

.header__burger.open span:nth-child(1) {
  transform: rotate(45deg);
  top: -3px;
  left: 6px;
  width: 29px;
}

.header__burger.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.header__burger.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 17.33px;
  left: 6px;
  width: 29px;
}

