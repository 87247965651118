.about {
  padding: 60px 0 0;

  @media (max-width: 1200px) {
    padding: 60px 0 30px;
  }

  @media (max-width: $mobile) {
    padding: 30px 0;
  }
}

.about__title {
  color: $pink;
  margin-bottom: 29px;

  @media (max-width: $mobile) {
    margin-bottom: 21px;
  }
}

.about__container {
  display: flex;
  gap: 15px;
  justify-content: space-between;

  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: center;
  }
}

.about__left {
  max-width: 626px;
}

.about__right {
  width: 100%;
  max-width: 556px;
  margin-top: -60px;

  & img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: $tablet) {
    margin-top: 0;
  }
}