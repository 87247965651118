.footer {
  margin-bottom: 85px;
  padding: 20px 0;
  text-align: center;
  color: #636466;

  @media (max-width: 1465px) {
    margin-bottom: 55px;
  }

  @media (max-width: 692px) {
    margin-bottom: 82px;
  }

  @media (max-width: $mobile) {
    padding: 5px 0;
    font-size: 10px;
  }
}

.footer__address {
  margin-bottom: 1px;
  font-style: normal;

  @media (max-width: $mobile) {
    margin-bottom: 4px;
  }
}