.title {
  margin: 0;
  padding: 0;
  font-size: 45px;
  font-weight: 400;
  line-height: 1.15;

  @media (max-width: $desktop) {
    font-size: 24px;
  }

  @media (max-width: $mobile) {
    text-align: center;
  }
}

