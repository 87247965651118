.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 20px 0;
  text-align: center;
  font-family: "roboto", Arial;
  font-weight: 400;
  font-size: 35px;
  color: #636466;
  background-color: #F3F4F9;

  @media (max-width: 1465px) {
    font-size: 16px;
  }

  @media (max-width: 340px) {
    font-size: 14px;
  }
}