.note {
  padding: 60px 0;
  color: #fff;
  font-size: 12px;

  @media (max-width: $mobile) {
    padding: 30px 0;
    font-size: 8px;
  }
}

.note__list {
  margin-left: .8rem;
}

.note__item {
  list-style: decimal;
}