.hero__container {
  position: relative;
}

.hero__top .hero__container:before {
  content: "";
  width: 811px;
  height: 813px;
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 0;
  background-image: url("/img/hero.webp");
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: $desktop) {
    width: 420px;
    height: 90%;
  }

  @media (max-width: $tablet) {
    top: 30px;
    width: 320px;
    height: 90%;
  }

  @media (max-width: 768px) {
    right: 0;
  }

  @media (max-width: $mobile) {
    background-image: url("/img/hero-small.webp");
  }
}

.hero__title {
  margin: 0 0 30px;
  font-size: 45px;
  font-weight: 400;
  text-transform: uppercase;
  color: $pink;
  line-height: 1.1;

  @media (max-width: $tablet) {
    max-width: 350px;
    font-size: 24px;
  }

  @media (max-width: $mobile) {
    margin: 0 0 20px;
    max-width: 270px;
  }
}

.hero__top {
  background-image: linear-gradient(167deg, rgba(239, 207, 255, 0), rgb(233, 190, 254) 100%)
}

.hero__text {
  max-width: 620px;
  padding: 128px 0;

  @media (max-width: $mobile) {
    padding: 187px 0 130px;
  }
}

.hero__desc {
  color: #636466;
  font-size: 32px;
  line-height: 1.1;

  @media (max-width: $tablet) {
    max-width: 300px;
    font-size: 14px;
  }

  @media (max-width: $mobile) {
    max-width: 223px;
  }
}

.hero__discomfort {
  position: relative;
  z-index: 2;
  padding: 61px 0 78px;

  @media (max-width: $tablet) {
    padding: 30px 0;
  }
}

.hero__list {
  display: flex;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: $tablet) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.hero__item {
  max-width: 232px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  color: #fff;

  @media (max-width: $mobile) {
    gap: 9px;
  }
}

.hero__discomfort-img-block {
  width: 67px;
  height: 47px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: $mobile) {
    width: 40px;
    height: 28px;
  }
}

.hero__discomfort-title {
  margin-bottom: 2px;
  font-weight: 700;
  text-shadow: rgba(#fff, .5) 0 0 10px;
  text-align: center;

  @media (max-width: $mobile) {
    margin-bottom: 0;
  }
}

.hero__discomfort-desc {
  padding: 0 9px;
  font-size: 16px;
  text-align: center;
  line-height: 1.125;

  @media (max-width: $mobile) {
    padding: 0 19px;
    font-size: 12px;
  }
}

.hero__btn {
  display: none;

  @media (max-width: $mobile) {
    display: inline-block;
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}