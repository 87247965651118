
//MEDIA
$desktop: 1280px;
$tablet: 992px;
$mobile: 576px;


//COLORS
$pink: #6C217F;

//FONTS
$roboto: "roboto", Arial, sans-serif;


